import httpInstance from '../httpInstance';

const getPaymentLinksByDateService = async ({
  pageNumber = 0, pageSize = 80, searchKey = '', searchValue = '',
}) => {
  let res;
  const aditionalQueryParam = searchValue.trim() === '' ? '' : `&${searchKey}=${searchValue}`;
  const endpoint = `/commerce-account/v1/commerce-info/payment-links/allPaginated?pageNumber=${pageNumber}&pageSize=${pageSize}${aditionalQueryParam}`;
  // ${searchQuery ? `&${keyFilter}=${searchQuery}` : ''}`;
  // const headers = { 'X-Cognito-Custom-Commerce-Id': comerceId };
  await httpInstance.get(
    endpoint,
    // { headers },
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getPaymentLinksByDateService;
